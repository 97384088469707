<template>
  <div class="payment-method-box" :class="{'selected': isSelected}">
    <div class="save-label">50% Beta Discount</div>
    <div class="method-plan-and-check-wrapper">
      <div class="plan-name">{{ planName }}</div>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" width="20" height="20" fill="#e5e7eb"
           aria-hidden="true">
        <path fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"></path>
      </svg>
    </div>
    <div class="plan-description"> {{ planDescription }}</div>
    <div class="plan-pricing"><span style="text-decoration: line-through;margin-right:10px;opacity:80%;"> ${{
        subscriptionOldPrice
      }} USD</span>
      <span>${{ subscriptionPrice }} USD</span> /
      {{ subscriptionInterval }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    isSelected: {
      type: Boolean,
      default: false
    },
    planName: {
      type: String,
      required: true
    },
    planDescription: {
      type: String,
      required: true
    },
    subscriptionPrice: {
      type: Number,
      required: true
    },
    subscriptionOldPrice: {
      type: Number,
      required: true
    },
    subscriptionInterval: {
      type: String,
      required: true
    },
    saveLabelText: {
      type: String,
      required: false
    }
  }
}
</script>

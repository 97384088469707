<template>
  <div>
    <div class="form-title mb-50">
      Activate your
      <span class="text-primary font-weight-max-bold">7 day free trial!</span>
    </div>
    <div>
      Unlimited accounts, unlimited data. Cancel your trial at any time.
    </div>

    <div class="payment-methods-wrapper my-2">
      <payment-method-box
          v-for="(plan, index) in plans" :key="index"
          @click.native="selectedPlan = plan"
          :class="{ 'mb-2': index !== plans.length - 1 }"
          :is-selected="selectedPlan && selectedPlan.id === plan.id"
          :plan-name="plan.name"
          :plan-description="plan.description"
          :subscription-price="plan.price"
          :subscription-old-price="plan.oldPrice"
          :subscription-interval="plan.interval"
          :save-label-text="plan.saveLabel || null"
      />
    </div>

    <validation-observer ref="setupPaymentsValidation">
      <form @submit.prevent="subscribe">
        <validated-form-input
            label="Cardholder Name"
            input-id="cardholder-name-input"
            validation-name="cardholder name"
            placeholder="Enter the name of the cardholder"
            v-model="cardholderName"
        />


        <b-form-group label="Payment Information" label-for="payment-information-input">
          <div class="stripe-input-wrapper">
            <div v-if="!stripeInstance" class="stripe-loading-text">Stripe loading...</div>
            <div id="stripe-card-element"></div>
          </div>
        </b-form-group>

        <b-button type="submit" class="w-100 mt-1 mt-md-2 submit-form-btn" :disabled="loading">
          <template v-if="loading">
            <b-spinner small class="mr-50"/>
            <span class="align-middle">Loading</span>
          </template>
          <span v-else>Start My 7 Day Free Trial</span>
        </b-button>
        <p v-if="selectedPlan" class="font-small-2">* You will be billed ${{ selectedPlan ? billAmountAfterTrial : '' }}
          on
          {{ startDateBill }}.</p>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import {ValidationObserver} from "vee-validate";
import {BFormGroup, BSpinner, BButton} from "bootstrap-vue";
import AuthCardLeft from "@/layouts/components/auth/AuthCardLeft";
import PaymentMethodBox from "@/layouts/components/auth/PaymentMethodBox";
import ValidatedFormInput from "@/layouts/components/shared/ValidatedFormInput";
import PaymentsService from "@/services/PaymentsService";
import toastsMixin from "@/mixins/toastsMixin";

export default {
  components: {
    BFormGroup,
    BSpinner,
    BButton,
    ValidationObserver,
    AuthCardLeft,
    PaymentMethodBox,
    ValidatedFormInput
  },
  mixins: [toastsMixin],
  data() {
    return {
      plans: [
        {
          id: 0,
          name: 'Monthly',
          description: '',
          price: 99,
          oldPrice: 199,
          interval: 'month',
        },
        {
          id: 1,
          name: 'Yearly',
          description: '',
          price: 79,
          oldPrice: 159,
          interval: 'year',
          saveLabel: 'Save 16%'
        }
      ],
      selectedPlan: null,

      loading: false,

      cardholderName: '',

      clientSecret: null,
      stripeInstance: null,
    }
  },
  computed: {
    billing() {
      if (!this.selectedPlan) return null;
      if (this.selectedPlan.interval === 'year') return 'yearly';
      if (this.selectedPlan.interval === 'month') return 'monthly';
      return null;
    },
    billAmountAfterTrial() {
      if (!this.selectedPlan) return null;
      if (this.selectedPlan.interval === 'year') return this.selectedPlan.price * 12;
      if (this.selectedPlan.interval === 'month') return this.selectedPlan.price;
      return null;
    },
    startDateBill() {
      // get date after 7 days
      const date = new Date();
      date.setDate(date.getDate() + 7);
      return date.toLocaleDateString('en-US', {
        day: 'numeric',
        month: 'short',
        year: 'numeric'
      });

    }
  }
  ,
  mounted() {
    this.selectedPlan = this.plans.find(plan => plan.interval === 'year');
    this.initStripe();
  }
  ,
  methods: {
    async initStripe() {
      const {data: {intent: {client_secret}}} = await PaymentsService.get.paymentsSecret();
      this.clientSecret = client_secret;
      this.stripeInstance = Stripe('pk_test_51H9xEtCX9pz8QNkLtfK3lUAEo37qdOFuiJJlFNPDkVC1W4q344kLNYBg89CCOJJuToZGG3q5AmxfZau7O5yAFQzM005SsEO5dq');
      // this.stripeInstance = Stripe('pk_live_51LDVREBRim70T8FMSdPRHOfnY0FFLNo6v9OS2AZLgOqySteygdnrniNG0z7OM5YqBRxTuiU5ydwJcBVjRyXCamwr00Oi6T33vQ');

      this.stripeCardElement = this.stripeInstance.elements().create('card');
      this.stripeCardElement.mount('#stripe-card-element');
    }
    ,

    async subscribe() {
      const isFormValid = await this.$refs.setupPaymentsValidation.validate();

      if (!isFormValid || this.loading) return;

      this.loading = true;
      const {setupIntent, error} = await this.stripeInstance.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: {
              card: this.stripeCardElement,
              billing_details: {name: this.cardholderName}
            }
          }
      );

      if (error) {
        this.showErrorToast(error.message);
        this.loading = false;
        return
      }

      await PaymentsService.post.subscribe(setupIntent.payment_method, this.billing);
      await this.$store.dispatch('auth/authorizeFromLocalJWT');

      this.loading = false;
      this.showSuccessToast('Payment successful');

      return this.$router.push({name: 'auth.setup-api-key'});
    }
  }
}
</script>

import httpClient from "@/client";

export default {
    get: {
        paymentsSecret: () => {
            return httpClient.get('/api/account/setup/payments/secret');
        },
        paymentMethods: () => {
            return httpClient.get('/api/account/billing/payment_methods');
        },
        subscriptionInfo() {
            return httpClient.get('/api/account/billing/subscription');
        }
    },
    post: {
        subscribe: (payment_method, billing) => {
            return httpClient.post('/api/account/setup/payments/subscribe', {
                payment_method,
                billing
            });
        },
        addPaymentMethod: (payment_method, billing) => {
            return httpClient.post('/api/account/billing/payment_methods', {
                payment_method,
                billing
            });
        },
        makeDefault: payment_method_id => {
            return httpClient.post(`/api/account/billing/payment_methods/${payment_method_id}/default`);
        },
        resumeSubscription: () => {
            return httpClient.post('/api/account/billing/subscription/resume');
        },
        cancelSubscription: () => {
            return httpClient.post('/api/account/billing/subscription/cancel');
        }
    },
    delete: {
        paymentMethod: payment_method_id => {
            return httpClient.delete(`/api/account/billing/payment_methods/${payment_method_id}`);
        }
    }
}
